import { Injectable, OnDestroy } from '@angular/core';
import { SmeService } from '../../core/sme/sme.service';
import { ResourceUpload } from '../../core/firebase/resource-upload';
import { MapStateService } from '../../shared/map-state.service';
import { SmService } from 'supermappe-core';
import { InputDialogService } from '../../shared/dialog/input-dialog.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { Logger } from '../../core/logger.service';
import { ConfirmationService } from '../../shared/dialog/confirmation.service';
import { MapOperationType } from '../../shared/map-operations';
import { Ng2ImgMaxService } from 'ng2-img-max';

const logger = new Logger('ImageMapsService');

@Injectable({
    providedIn: 'root'
})
export class ImageMapsService implements OnDestroy {

    insertImageSubscription: Subscription | undefined;
    uploadImageFromUriSubscription: Subscription | undefined;
    confirmSubscription: Subscription | undefined;
    askUrlSubscription: Subscription | undefined;
    openDialogSubscription: Subscription | undefined;
    insertImageFromDriveSubscription: Subscription | undefined;
    // insertImageFromSvgSubscription: Subscription | undefined;

    constructor(
        private smService: SmService,
        private mapStateService: MapStateService,
        private translateService: TranslateService,
        private smeService: SmeService,
        private inputDialogService: InputDialogService,
        private ng2ImgMax : Ng2ImgMaxService,
        private confirmationService: ConfirmationService) {
    }

    ngOnDestroy() {
        if (this.insertImageSubscription) { this.insertImageSubscription.unsubscribe(); }
        if (this.uploadImageFromUriSubscription) { this.uploadImageFromUriSubscription.unsubscribe(); }
        if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
        if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
        if (this.openDialogSubscription) { this.openDialogSubscription.unsubscribe(); }
        if (this.insertImageFromDriveSubscription) { this.insertImageFromDriveSubscription.unsubscribe(); }
    }

    compressImage(file: File, maxSizeInMB: number): Observable<any> {
        return new Observable((observer: Observer<boolean>) => {
            this.ng2ImgMax.resizeImage(file, 500, 500).subscribe({
                next: (compressedImage: any) => {
                    // this.ng2ImgMax.compressImage(compressedImage, maxSizeInMB).subscribe({
                    //     next: (compressedImage: any) => {
                            observer.next(compressedImage);
                            observer.complete();
                    //    }
                   // })
                },
    
            error: (error: any) => {
                observer.error(error);
                observer.complete();
                }
            });
        });
    }

    private insertImageCompressedFromFile(currentImageFile: File, node: any) {
         const mapId = this.mapStateService.id;
            // const data = this.getNode();
            let data: any;
            if (node) {
                data = node;
            } else {
                data = this.getNode();
            }
            if (data.node || node) {
                let targetNodeId: string;
                if (data.node) {
                    targetNodeId = data.node.id;
                } else {
                    targetNodeId = data.id;
                }
                this.smService.setImagePlaceholderToNodeId(targetNodeId, true);
                const currentImageUpload = new ResourceUpload(currentImageFile);
                if (this.insertImageSubscription) { this.insertImageSubscription.unsubscribe(); }
                this.insertImageSubscription = this.smeService.insertImage(currentImageUpload, mapId, targetNodeId)
                    .subscribe({
                        next: (image: any) => {
                            this.insertImageFromUri(image.url, image.destPath, targetNodeId, data.created);
                        },
                        error: (error: any) => {
                            let errorCode = '';
                            if (error.status === 504) {
                                errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                            } else {
                                errorCode = (error.code) ? error.code : 'ERROR_INSERT_IMAGE';
                            }
                            this.smService.setImagePlaceholderToNodeId(targetNodeId, false);
                            this.mapStateService.setStateError(errorCode);
                            if (data.created) {
                                this.smService.deleteSelection();
                            }
                        }
                    });
            } else {
                this.mapStateService.setStateError('ERROR_INSERT_IMAGE');
                if (data.created) {
                    this.smService.deleteSelection();
                }
            }
    }

    insertImageFromFile(currentImageFile: File, node: any) {
        if (currentImageFile) {
        
            const percentageReduction = 0.95;
            const targetFileSize = currentImageFile.size * (1 - percentageReduction);
            const maxSizeInMB = targetFileSize * 0.000001;
            this.compressImage(currentImageFile, maxSizeInMB).subscribe({
                next: (compressedImage: any) => {
                   this.insertImageCompressedFromFile(compressedImage, node);
                },
                error: (error: any) => {
                    let errorCode = '';
                    if (error.status === 504) {
                        errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
                    } else {
                        errorCode = error.error.message;
                    }
                    console.log(error);
                    this.insertImageCompressedFromFile(currentImageFile, node);
                }
            });
        }
    }

    // insertImageFromSvg(svg: string, targetNodeId: any) {
    //     if (svg) {
    //         const mapId = this.mapStateService.id;
    //         this.smService.setImagePlaceholderToNodeId(targetNodeId, true);
    //         // const currentImageUpload = new ResourceUpload(currentImageFile);
    //         if (this.insertImageSubscription) { this.insertImageSubscription.unsubscribe(); }
    //         this.insertImageFromSvgSubscription = this.smeService.insertImageFromSvg(svg, mapId, targetNodeId)
    //             .subscribe({
    //                 next: (image: any) => {
    //                     this.insertImageFromUri(image.url, image.destPath, targetNodeId, true);
    //                 },
    //                 error: (error: any) => {
    //                     let errorCode = '';
    //                     if (error.status === 504) {
    //                         errorCode = this.translateService.instant(extract('OFFLINE_GENERIC'));
    //                     } else {
    //                         errorCode = (error.code) ? error.code : 'ERROR_INSERT_IMAGE';
    //                     }
    //                     this.smService.setImagePlaceholderToNodeId(targetNodeId, false);
    //                     this.mapStateService.setStateError(errorCode);
    //                 }
    //             });
    //     }
    // }

    openDialog(urlToOpen: string) {
        return new Observable((observer: Observer<boolean>) => {
            this.mapStateService.setFocusOnMap(false);
            this.mapStateService.lockUI(false, false);
            if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
            this.askUrlSubscription = this.inputDialogService.askUrl(this.translateService.instant(extract('INSERT-IMAGE')),
                this.translateService.instant(extract('INPUT-IMAGE-URL')), '',
                this.mapStateService.id, urlToOpen)
                .subscribe({
                    next: (result) => {
                        this.mapStateService.setFocusOnMap(true);
                        if (result === '') { result = false; }
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error: any) => {
                        this.mapStateService.setFocusOnMap(true);
                        observer.next(false);
                        observer.complete();
                    }
                });
        });
    }

    // private lockUi(locked: boolean) {
    //   this.mapStateService.setEditingState(locked);
    //   this.mapStateService.setBusyState(locked);
    //   this.mapStateService.setCanEnableQuickEdit(!locked);
    //   this.smService.setEnableKeyPresses(!locked);
    // }

    insertImageFromDrive(googleId: string, storagePath?: string, targetNodeIdOutside?: string, nodeCreatedOutside?: boolean) {

        if (navigator.onLine) {
            let targetNodeId = '';
            let data: any;

            if (targetNodeIdOutside) {
                nodeCreatedOutside = true;
                targetNodeId = targetNodeIdOutside;
            } else {
                data = this.getNode();
                targetNodeId = data.node.id;
            }

            if (targetNodeId) {
                this.smService.setImagePlaceholderToNodeId(targetNodeId, true);
                const mapId = this.mapStateService.id;
                this.mapStateService.lockUI(true, true);
                if (this.insertImageFromDriveSubscription) { this.insertImageFromDriveSubscription.unsubscribe(); }

                this.insertImageFromDriveSubscription = this.smeService.insertImageFromDrive(googleId, mapId, targetNodeId, storagePath)
                    .subscribe({
                        next: (imageData: any) => {
                            const imageInfo = { name: imageData.res, transparent: false, custom: false };
                            this.smService.setImageToNodeId(targetNodeId, imageInfo, false);
                            this.mapStateService.lockUI(false, false);
                            this.mapStateService.dropping = false;
                        },
                        error: (error: any) => {
                            if (error.status === 504) {
                                error = this.translateService.instant(extract('OFFLINE_GENERIC'));
                            } else {
                                error = 'ERROR_INSERT_IMAGE';
                            }
                            this.mapStateService.dropping = false;
                            this.smService.setImagePlaceholderToNodeId(targetNodeId, false);
                            this.mapStateService.lockUI(false, false);
                            if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
                            this.confirmSubscription = this.confirmationService.confirm(
                                this.translateService.instant(extract('WARNING')),
                                this.translateService.instant(extract(error)),
                                this.translateService.instant(extract('BUTTON_OK')),
                                '').subscribe(result => {
                                    if (data.created || nodeCreatedOutside) {
                                        this.smService.deleteSelection();
                                    }
                                });
                        }
                    });
            } else {
                this.mapStateService.dropping = false;
            }
        } else {
            const error = this.translateService.instant(extract('OFFLINE_GENERIC'));
            this.mapStateService.dropping = false;
            this.mapStateService.lockUI(false, false);
            if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract(error)),
                this.translateService.instant(extract('BUTTON_OK')),
                '');
        }
    }

    insertImageFromUriWithDialog() {
        let uri: string;
        this.mapStateService.lockUI(true, true);
        if (this.openDialogSubscription) { this.openDialogSubscription.unsubscribe(); }
        this.openDialogSubscription = this.openDialog('')
            .subscribe((result: any) => {
                if (result) {
                    uri = result.name;
                    this.insertImageFromUri(uri);
                } else {
                    this.mapStateService.lockUI(false, false);
                }
            });
    }

    insertImageFromUri(uri: string, storagePath?: string, targetNodeIdOutside?: string, nodeCreatedOutside?: boolean) {
        if (navigator.onLine) {
            let targetNodeId = '';
            let data: any;
            if (targetNodeIdOutside) {
                nodeCreatedOutside = true;
                targetNodeId = targetNodeIdOutside;
            } else {
                data = this.getNode();
                targetNodeId = data.node.id;
            }
            if (targetNodeId) {
                this.smService.setImagePlaceholderToNodeId(targetNodeId, true);
                const mapId = this.mapStateService.id;
                if (this.uploadImageFromUriSubscription) { this.uploadImageFromUriSubscription.unsubscribe(); }
                this.uploadImageFromUriSubscription = this.smeService.uploadImageFromUri(uri, mapId, targetNodeId, storagePath)
                    .subscribe({
                        next: (imageData: any) => {
                            const imageInfo = { name: imageData.res, transparent: false, custom: false };
                            this.smService.setImageToNodeId(targetNodeId, imageInfo, false);
                            this.mapStateService.lockUI(false, false);
                            this.mapStateService.dropping = false;
                            if (this.mapStateService.mapOperations) {
                                this.mapStateService.mapOperations.add(MapOperationType.STAT_ADDIMAGE);
                            }
                        },
                        error: (error: any) => {
                            if (error.status === 504) {
                                error = this.translateService.instant(extract('OFFLINE_GENERIC'));
                            } else {
                                error = 'ERROR_INSERT_IMAGE';
                            }
                            this.mapStateService.dropping = false;
                            this.smService.setImagePlaceholderToNodeId(targetNodeId, false);
                            this.mapStateService.lockUI(false, false);
                            if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
                            if (!uri.startsWith('https://lh3.googleusercontent.com/d/')) {
                                this.confirmSubscription = this.confirmationService.confirm(
                                    this.translateService.instant(extract('WARNING')),
                                    this.translateService.instant(extract(error)),
                                    this.translateService.instant(extract('BUTTON_OK')),
                                    '').subscribe(result => {
                                        if (data.created || nodeCreatedOutside) {
                                            this.smService.deleteSelection();
                                        }
                                    });
                            }
                        }
                    });
            } else {
                this.mapStateService.dropping = false;
            }
        } else {
            const error = this.translateService.instant(extract('OFFLINE_GENERIC'));
            this.mapStateService.dropping = false;
            this.mapStateService.lockUI(false, false);
            if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract(error)),
                this.translateService.instant(extract('BUTTON_OK')),
                '');
        }
    }

    convertArrayToBase64(array: any): string {
        const bytes = new Uint8Array(array);
        const len = bytes.byteLength;
        let binary = '';
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64 = (window as any).btoa(binary);
        const dataUrl = 'data:image/png;base64,' + base64;
        return dataUrl;
    }

    private getNode(): any {
        const result = { node: {}, created: false };
        result.node = this.smService.getSelectedNode();
        if (result.node === null) {
            result.node = this.smService.addNodeAndSelect(this.mapStateService.selectionData.scrCenterX, this.mapStateService.selectionData.scrCenterY);
            result.created = true;
        }
        return result;
    }

}
