import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CALLER, TtsService } from '../../commands/tts.service';
import { SmService } from 'supermappe-core';
import { QuickEditService } from '../../commands/quick-edit.service';
import { MapStateService } from '../../map-state.service';
import { UiConstants } from '../../ui-constants';
import { CustomSelectElement } from '../../../map-edit/toolbar/custom-toolbar/custom-select-element-dto';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WebSearchService } from '../../commands/web-search.service';
import { SpeechRecognitionService } from '../../speech/speechrecognition.service';
import { SpeechRecognitionResult } from '../../speech/speech-recognition-result';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UserPreferenceService } from '../../../shared/user-preference.service';
import { DeviceService } from 'src/app/core/device.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

declare let CKEDITOR: any;

@Component({
    selector: 'app-dialog-deep',
    templateUrl: './dialog-deep.component.html',
    styleUrls: ['./dialog-deep.component.scss']
})
export class DialogDeepComponent implements OnInit, OnDestroy {

    @ViewChild('speechInput') speechInput: ElementRef | undefined;

    public htmlSafe: SafeHtml | undefined;
    private html = '';
    public ttsEnable = false;
    public viewMode = true;
    private plainText = '';
    private _currLanguage = UiConstants.LANGUAGES[0].value;
    private nodeId = '';
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    public languagesWithFlag: CustomSelectElement[] = new Array<CustomSelectElement>();
    public languagesNoFlag: CustomSelectElement[] = new Array<CustomSelectElement>();
    public speechEditEnabled: boolean;
    public isTalking: boolean = false;
    private showNodeImageCookie = "showDeepNodeImage";
    private onTTSTalkingStart: Subscription | undefined;
    private onTTSTalkingEnd: Subscription | undefined;

    onGetVoicesSubscription: Subscription | undefined;
    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    // selectedLanguage = UiConstants.LANGUAGES[0].value;
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    imageNodeUrl: any;
    imagePresent = false;
    showNodeImage: boolean = true;
    rateValue = 1;
    private editor: any;
    compressed: boolean = false;
    isMobileMode = false;
    isMobileDevice = false;


    breakpointObserverSubscription: Subscription | undefined;
    @ViewChild('htmlView') public htmlView: ElementRef | undefined;

    public canEdit = false;
    public editVisible = false;

    constructor(
        private sanitizer: DomSanitizer,
        private ttsService: TtsService,
        private smService: SmService,
        private translateService: TranslateService,
        private authService: AuthenticationService,
        private quickEditService: QuickEditService,
        private webSearchService: WebSearchService,
        private mapStateService: MapStateService,
        private breakpointObserver: BreakpointObserver,
        private userPreferencesService: UserPreferenceService,
        private speechRecognitionService: SpeechRecognitionService,
        public deviceService: DeviceService
    ) {

        this.breakpointObserverSubscription = this.breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small])
            .subscribe((state: BreakpointState) => {
                //this.compressed = state.matches;
            });


        this.isMobileMode = this.deviceService.isMobileMode();
        this.isMobileDevice = this.deviceService.isMobileOrTabletDevice();

        const usermail = this.authService.getUserEmail();
        const filter: Array<string> = [usermail];
        const savedShowNodeImage = userPreferencesService.getCookie(this.showNodeImageCookie, filter);
        if (savedShowNodeImage !== '') {
            this.showNodeImage = savedShowNodeImage === 'true';
        } else {
            this.showNodeImage = !this.isMobileMode;
        }


        CKEDITOR.config.language = this.translateService.currentLang;
        CKEDITOR.config.defaultLanguage = this.translateService.currentLang;
        CKEDITOR.config.fullPage = false;
        CKEDITOR.config.resize_enabled = false;

        //CKEDITOR.config.extraPlugins += 'autogrow';
        //CKEDITOR.config.autoGrow_minHeight = 200;
        //CKEDITOR.config.autoGrow_maxHeight = (screen.height - 150);

        CKEDITOR.config.font_defaultLabel = UiConstants.deepFontStyle['font'];
        CKEDITOR.config.fontSize_defaultLabel = UiConstants.deepFontStyle['size'];
        CKEDITOR.config.font_names = '';
        CKEDITOR.addCss(".cke_editable{cursor:text; font-size: 14px; font-family: Arial, sans-serif;}");
        CKEDITOR.config.fontSize_sizes = '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;24/24pt;30/30pt;36/36pt;48/48pt';
        const myFonts = ['Arial', 'Verdana', 'sans-serif', 'Architects Daughter', 'IBM Plex Mono', 'Montserrat', 'Roboto', 'Special Elite'];

        for (let i = 0; i < myFonts.length; i++) {
            CKEDITOR.config.font_names = CKEDITOR.config.font_names + ';' + myFonts[i];
            myFonts[i] = 'http://fonts.googleapis.com/css?family=' + myFonts[i].replace(' ', '+');
        }
        CKEDITOR.config.contentsCss = ['/assets/ckeditor/contents.css'].concat(myFonts);
        CKEDITOR.config.fontStyle_size = '24px';
        CKEDITOR.config.toolbarLocation = 'top';

        CKEDITOR.config.allowedContent = true;

        this.viewMode = this.mapStateService.viewMode;
        this.speechEditEnabled = false;
    }

    ngOnInit() {
        this.mapStateService.setFocusOnMap(false);
        this.ttsEnable = false;
        this.quickEditService.onCanEdit.emit(false);
        this.mapStateService.setEditingState(true);
        this.onGetVoicesSubscription =
            this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
                if (this.deviceService.isMobileOrTabletDevice()) {
                    this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
                } else {
                    this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
                }
                this.sortLanguages();
            });
        if (this.ttsService.voices) {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(this.ttsService.voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(this.ttsService.voices, this.translateService);
            }
            this.sortLanguages();
        }
        this.setLanguage(this._currLanguage);

        this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
        this.ttsService.voiceRate = this.rateValue;


        this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditService.origin === this.quickEditService.ORIGIN.DEEP) {
                this.speechEditEnabled = enabled;
                if (enabled) {
                    this.startSpeechEdit();
                } else {
                    this.stopSpeechEdit();
                }
            }
        });

        this.onTTSTalkingStart = this.ttsService.TalkingStart.subscribe(() => {
            this.isTalking = true;

        });

        this.onTTSTalkingEnd = this.ttsService.TalkingEnd.subscribe(() => {
            this.isTalking = false;
        });
    }

    private startSpeechEdit() {
        if (this.speechInput) {
            this.speechInput.nativeElement.innerHTML = '';
            if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
            this.speechRecordSubscription = this.speechRecognitionService.record(this._currLanguage)
                .subscribe({
                    next: (srr: SpeechRecognitionResult) => {
                        const text: string = srr.transcript;
                        if (srr.isFinal) {
                            // End speech recognition
                            if (text === 'ok' || text === 'stop') {
                                console.log('speech stopped!');
                                this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
                            } else {
                                console.log('speech complete!');
                                const editor = CKEDITOR.instances['deepeditor-container'];
                                if (editor) {
                                    editor.insertText(text + ' ');
                                }
                                this.speechRecognitionService.DestroySpeechObject();
                                if (this.quickEditService.isSpeechEditEnabled()) {
                                    this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
                                }
                            }
                            console.log(text);
                        } else {
                            // Partial speech recognition
                            // this.speechInput.nativeElement.value = text;
                            if (this.speechInput) {
                                this.speechInput.nativeElement.innerHTML = UiConstants.getDefaultHtmlForDeep(text);
                            }
                        }
                    },
                    // error
                    error: (err) => {
                        if (err.error === 'no-speech') {
                            console.log('SpeechEdit error: no-speech');
                            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
                        }
                    },
                    // completion
                    complete: () => {
                        console.log('SpeechEdit: complete');
                    }
                });
        }
    }

    private stopSpeechEdit() {
        console.log('stopSpeechEdit');
        this.speechRecognitionService.DestroySpeechObject();
    }

    clickLink(event: any) {
        const url = event.srcElement.href;
        if (!url) {
            return;
        }

        window.open(url, '_blank');
        event.preventDefault();
    }

    private getSavedReadSpeedByCurrentLanguage(): number {
        const usermail = this.authService.getUserEmail();
        if (usermail != '') {
            const filter: Array<string> = [usermail, this._currLanguage];
            const savedReadSpeed = this.userPreferencesService.getReadSpeedCookie(filter);
            if (savedReadSpeed > 0) {
                return savedReadSpeed;
            } else {
                return 1;
            }
        }

        return 1;
    }

    changeSpeechRate(event: any) {
        this.rateValue = event.target.value;
        this.ttsService.voiceRate = event.target.value;

        const usermail = this.authService.getUserEmail();
        if (usermail != '') {
            this.userPreferencesService.saveReadSpeedCookie(usermail, this._currLanguage, this.rateValue);
        }
    }

    public setImageNode(id: string) {
        this.nodeId = id;
        const data = this.smService.getNodeDataUrl(id);

        this.imageNodeUrl = data;
        this.imagePresent = data !== null;
    }

    public setHtmlContent(html: string) {
        const edit = (html === '');
        this.html = html;

        if (this.html === '') {
            this.html = this.applyDefaultStyle('');
        }
        this.htmlSafe = this.sanitizer.bypassSecurityTrustHtml(this.html);
        if (edit) {
            setTimeout(() => {
                this.editDeep(true);
            }, 10);
        }
    }

    private applyDefaultStyle(html: string): string {
        console.log("APPLYDEFAULTSTYLE");
        let res = '<p>';
        res += '<span style="font-family:' + UiConstants.deepFontStyle['font'] + ';">';
        res += '<span style="color:' + UiConstants.deepFontStyle['color'] + ';">';
        res += '<span style="font-size:' + UiConstants.deepFontStyle['size'] + 'pt;">';
        if (UiConstants.deepFontStyle['effect'] === 'PREF_EFFECT_BOLD') {
            res += '<strong>';
        }
        res += '&nbsp;';
        res += html;
        if (UiConstants.deepFontStyle['effect'] === 'PREF_EFFECT_BOLD') {
            res += '</strong>';
        }
        res += '</span></span></span></p>';
        return res;
    }

    private setLanguage(currLanguage: string) {
        this._currLanguage = currLanguage;
        if (this.languages) {
            const language = UiConstants.findLanguage(this._currLanguage, this.languages);
            if (language && language.icon) {
                this.selectedLangIcon = language.icon;
                this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
                this.ttsService.voiceRate = this.rateValue;
            }
        }
        CKEDITOR.config.language = this._currLanguage.substr(0, 2);
        // CKEDITOR.config.scayt_sLang = this._currLanguage.replace(/-/g, '_');
        this.editor = CKEDITOR.instances['deepeditor-container'];
        if (this.editor) {
            this.editor.language = this._currLanguage.substr(0, 2);
            const content = this.editor.getData();
            this.editor.setData(content);
        }
    }

    private sortLanguages() {
        if (!this.languages || this.languages.length == 0) return;

        this.languagesNoFlag = this.languages.filter(x => !x.icon || x.icon == UiConstants.FLAGS[0].icon);
        this.languagesWithFlag = this.languages.filter(x => x.icon && x.icon != UiConstants.FLAGS[0].icon);
    }

    changeLanguage(event: any) {
        this.setLanguage(event.currentTarget.id);
        this.smService.setSelectionLanguage(this._currLanguage);
    }

    public editDeep(forceFocus = false) {
        console.log("EDITDEEP");
        this.editVisible = !this.editVisible;
        const deepeditor = document.getElementById('deepeditor-container');
        if (deepeditor && !this.editVisible) {
            if (this.quickEditService.isSpeechEditEnabled()) {
                this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
            }
            this.disableEditing();
            this.html = deepeditor.innerHTML;
            this.html = this.applyDefaultStyle(this.html);
            this.htmlSafe = this.sanitizer.bypassSecurityTrustHtml(deepeditor.innerHTML);
            this.plainText = deepeditor.innerText;
        } else {
            this.enableEditing(forceFocus);
        }
    }

    searchWeb(typeSearch: string) {
        this.webSearchService.textSearch = this.getText();
        if (this.webSearchService.textSearch === '' && this.nodeId) {
            const node = this.smService.getNodeById(this.nodeId);
            if (node && node.titleText) {
                this.webSearchService.textSearch = node.titleText;
            }
        }
        this.webSearchService.searchOnWeb(typeSearch);
        this.webSearchService.disableMagicSearch();
    }

    enableEditing(forceFocus = false) {

        // CKEDITOR.config.width = this.htmlView.nativeElement.clientWidth;
        // CKEDITOR.config.height = this.htmlView.nativeElement.clientHeight - 150;
        CKEDITOR.config.language = this.translateService.currentLang.substr(0, 2);
        CKEDITOR.config.defaultLanguage = this.translateService.currentLang.substr(0, 2);
        CKEDITOR.config.title = false;
        CKEDITOR.config.format_tags = 'p;h1;h2;h3;pre';
        //CKEDITOR.config.floatSpacePreferRight = true;
        CKEDITOR.config.removeButtons = 'Image';
        CKEDITOR.config.removePlugins = 'magicline';

        //CKEDITOR.config.floatSpaceDockedOffsetX = 0;
        //CKEDITOR.config.floatSpaceDockedOffsetY = 30;

        CKEDITOR.config.allowedContent = true;

        if (!CKEDITOR.instances['deepeditor-container']) {
            this.editor = CKEDITOR.inline('deepeditor-container', {
                extraPlugins: 'language,selectall,colorbutton,justify,copyformatting,font,contextmenu,menu,maximize,openlink',
                language: CKEDITOR.config.language,
                toolbarLocation: 'top',
                toolbar: [
                    { name: 'clipboard', items: ['Undo', 'Redo'] },
                    { name: 'styles', items: ['Format'] },
                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                    { name: 'basicstyles', items: ['Subscript', 'Superscript'] },
                    { name: 'paragraph', items: ['BulletedList'] },
                    { name: 'insert', items: ['SpecialChar', 'Image'] }
                ]

            });
            CKEDITOR.replace('deepeditor-container', {
                extraAllowedContent: 'img(*){*}[*]'
            });

            this.editor.on('instanceReady', (evt: any) => {
                CKEDITOR.language = this.translateService.currentLang.substr(0, 2);

                if (CKEDITOR.instances['deepeditor-container']) {
                    CKEDITOR.instances['deepeditor-container'].language = this.translateService.currentLang.substr(0, 2);
                    //CKEDITOR.instances['deepeditor-container'].autoGrow_minHeight = 200;
                    //CKEDITOR.instances['deepeditor-container'].autoGrow_maxHeight = 800;
                    CKEDITOR.instances['deepeditor-container'].resetDirty();
                    CKEDITOR.instances['deepeditor-container'].on('selectionChange', (ev: any) => {
                        let text = ev.data.selection.getSelectedText();
                        if (text === '' && this.plainText !== 'undefined') {
                            text = this.plainText;
                        }
                        this.webSearchService.textSearch = text;
                        console.log('textSearch ' + ev.data.selection.getSelectedText());
                        this.mapStateService.setSpellCheckerLanguages(this._currLanguage);
                    });
                    CKEDITOR.instances['deepeditor-container'].on('change', (ev: any) => {
                        console.log(ev.editor.document.$.activeElement.outerText);
                        this.webSearchService.textSearch = ev.editor.document.$.activeElement.outerText;
                    });
                    // if (forceFocus) {
                    setTimeout(() => {
                        if (this.plainText?.trim() !== 'undefined') {
                            this.webSearchService.textSearch = this.plainText?.trim();
                        }
                        CKEDITOR.instances['deepeditor-container'].focus();
                        this.setLanguage(this._currLanguage);
                        // Restore standard toolbar
                        CKEDITOR.config.removeButtons = '';
                    }, 700);
                    // }
                }
            });
        }
    }

    disableEditing() {
        if (CKEDITOR.instances['deepeditor-container']) {
            if (CKEDITOR.instances['deepeditor-container'].checkDirty()) {
                const data = { titleHtml: '', deepHtml: '' };
                const editorValue = CKEDITOR.instances['deepeditor-container'].getData();
                const node = this.smService.getSelectedNode();
                data.titleHtml = node.titleHtml;
                data.deepHtml = editorValue;
                data.deepHtml = UiConstants.normalizeHtmlForDeep(data.deepHtml);
                this.smService.editSelectedElement(data);
            }
            CKEDITOR.instances['deepeditor-container'].destroy();
        }
    }

    public setPlainContent(text: string, currLanguage: string) {
        this.plainText = text;
        this._currLanguage = currLanguage;
    }

    getSelectionText() {
        let text = '';
        if (this.editVisible) {
            text = CKEDITOR.instances['deepeditor-container'].getSelection().getSelectedText();
            text = text.replace(/\r?\n|\r/gm, ' '); // remove line breaks
            text = text.replace(/\s\s+/g, ' ')?.trim(); // remove double spaces
        } else {
            const t = window?.getSelection()?.toString();
            text = (t ? t : '');
        }
        return text;
    }

    public getText() {
        let textToRead = this.getSelectionText();
        if (!textToRead) {
            const CKEditor = CKEDITOR.instances['deepeditor-container'];
            let appuntiEditor = '';
            if (CKEditor) {
                appuntiEditor = CKEditor.getData();
            } else {
                appuntiEditor = this.html;
            }
            const elem = document.createElement('div');
            elem.innerHTML = appuntiEditor;
            this.plainText = elem.innerText;
            this.plainText = this.plainText.replace(/\r?\n|\r/gm, ' '); // remove line breaks
            this.plainText = this.plainText.replace(/\s\s+/g, ' ')?.trim(); // remove double spaces

            textToRead = this.plainText?.trim();
        }
        if (textToRead === undefined) {
            textToRead = '';
        }
        return textToRead;
    }

    public TTSRead() {
        const textToRead = this.getText();
        this.ttsService.speak(CALLER.map, textToRead, this._currLanguage, true);
        this.ttsEnable = true;
    }

    public TTSStop() {
        this.ttsService.stop();
        this.ttsEnable = false;
    }

    speedGotFocus() {
        this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(false);
        this.quickEditService.onCanEdit.emit(false);
        this.mapStateService.setFocusOnMap(false);
    }

    speedLostFocus() {
        this.checkSpeedValue();
        this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(true);
        this.quickEditService.onCanEdit.emit(true);
        this.mapStateService.setFocusOnMap(true);
    }

    checkSpeedValue() {
        if (this.rateValue < 0.5) {
            this.rateValue = 0.5;
        }
        if (this.rateValue > 1.5) {
            this.rateValue = 1.5;
        }
    }

    speechEdit() {
        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
    }

    ngOnDestroy(): void {
        this.smService.setEnableKeyPresses(true);
        this.ttsService.stop();
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        this.quickEditService.onCanEdit.emit(true);
        this.mapStateService.setEditingState(false);

        if (CKEDITOR.instances['deepeditor-container']) {
            this.disableEditing();
        }
        this.mapStateService.setFocusOnMap(true);
    }

    toggleNodeImage() {
        this.showNodeImage = !this.showNodeImage;
        const usermail = this.authService.getUserEmail();
        if (usermail != "") {
            this.userPreferencesService.saveCookie(usermail + "_" + this.showNodeImageCookie, this.showNodeImage.toString().toLocaleLowerCase());
        }
    }
}
