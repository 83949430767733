// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import env from './.env.json';
import rel from './.rel.json';

export const environment = {
    env: 'dev',
    test: false,
    production: false,
    version: env.npm_package_version + ' (dev)',
    build: rel.release,
    serverUrl: '',
    defaultLanguage: 'it-IT',
    supportedLanguages: [
        'it-IT',
        'en-GB'
    ],
    configFirebase: {
        apiKey: 'AIzaSyA1IR0MO8uBmgndSajoiVaKqlmSonuNgqY',
        authDomain: 'supermappexdev.firebaseapp.com',
        databaseURL: 'https://supermappexdev.firebaseio.com',
        projectId: 'supermappexdev',
        storageBucket: 'supermappexdev.appspot.com',
        messagingSenderId: '1028514329135'
    },
    firebaseClientId: '1028514329135-911jka5qr4e9agcchnbm3shmndb0uuao.apps.googleusercontent.com',
    scope: 'email profile https://www.googleapis.com/auth/drive.file  https://www.googleapis.com/auth/drive.install'
};
