import { Guid } from 'guid-typescript';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Point } from './point';
import { TranslateService } from '@ngx-translate/core';
import { SmService } from 'supermappe-core';
import { SmeService } from '../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { QuickEditService } from './commands/quick-edit.service';
import { MapOperations } from './map-operations';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class MapStateService implements OnDestroy {

    public DEFAULT_LIMIT_PRINT_SIZE = 5000;
    private _limitPrintSize: number = this.DEFAULT_LIMIT_PRINT_SIZE;
    public get limitPrintSize() {
        return this._limitPrintSize;
    }
    public set limitPrintSize(value: number) {
        this._limitPrintSize = value;
        const lps: string = value + '';
        localStorage.setItem('limitPrintSize', lps);
    }

    public PRINT_QUALITY_SIZES = [1000, 2000, 3000, 4000, 5000];

    private _googleUri = '';
    get googleUri(): string {
        return this._googleUri;
    }
    set googleUri(googleUri: string) {
        this._googleUri = googleUri;
        this.onGoogleUriChange.emit(this.googleUri);
    }

    private _isMine = false;
    get isMine(): boolean {
        return this._isMine;
    }
    set isMine(isMine: boolean) {
        this._isMine = isMine;
        this.onMapIsMine.emit(this.isMine);
    }

    public COPY_TYPE = {
        ALL: 'all',
        TEXT: 'text',
        DEEP: 'deep',
        IMAGE: 'image'
    };

    public guid = '';
    public id = '';
    public viewMode = true; // serve per capire se è in visualizzazione o in edit
    public readOnly = false;
    public googleFileId = '';
    public isShared = false;
    public fileRef: any;
    private isBusy = false;
    private isEditing = false;
    private _isFormatCopying = false;
    public isLocked = false;
    public workJson: any;
    public name = '';
    public author = '';
    public isNew = false;
    public canvasOrigin: Point | undefined;
    public editOnText = false;
    private autoSaving = false;
    public lastSaveIndex = -1;
    public index = -1;
    public _isDirty = false;
    public error = '';
    public mapPreview: any;
    public mapLanguage = '';
    public selectionData: any;
    public canEnableQuickEdit = false;
    public isLoaded = false;
    public isShowExiting = false;
    public forceExit = false;
    public dropping = false;
    public isCopyingMapImage = false;
    private isFocusOnMap = false;
    public googleDocFileId = '';
    public isSomeOperationStarted = false;
    public gcShareMapId = '';
    public copyType = '';
    public pasteMode = '';
    public hasDeepContent = false;
    public pasteWithDeepContent = false;

    public currentRecentGroup = 'all';

    public mapOperations: MapOperations | undefined;

    public onMapIsMine = new EventEmitter<boolean>();
    public onShareData = new EventEmitter<any>();
    public onExportChange = new EventEmitter<any>();
    public onGoogleUriChange = new EventEmitter<string>();
    public startSharing = new EventEmitter<any>();
    public stopSharing = new EventEmitter<string>();
    public onCanEnableQuickEditChange = new EventEmitter<boolean>();
    public onBusyStateChange = new EventEmitter<any>();
    public onAutoSavingChange = new EventEmitter<any>();
    public onEditingChange = new EventEmitter<any>();
    public onError = new EventEmitter<any>();
    public onLoaded = new EventEmitter<boolean>();
    public onDirtyStateChange = new EventEmitter<boolean>();
    public onForceAutosave = new EventEmitter<any>();
    public onChangedBackColor = new EventEmitter<any>();
    public onChangePasteState = new EventEmitter<boolean>();
    public onStartEditImage = new EventEmitter<any>();
    public onCopyFormatStateChange = new EventEmitter<boolean>();
    public onChangeMapName = new EventEmitter<string>();
    public onEventUser = new EventEmitter<any>();
    public refreshRecentSharing = new EventEmitter<string>();
    public onUserIsDemo = new EventEmitter<boolean>();
    public onGridChange = new EventEmitter<boolean>();
    public onClearMathErrors = new EventEmitter();
    public onMapGetFocus = new EventEmitter<boolean>();
    public onImageLoaded = new EventEmitter();

    private uploadWorkJsonSubscription: Subscription | undefined;
    private renameMapSubscription: Subscription | undefined;

    public isGridVisible = false;

    public isDeactivateDialogOpen: boolean = false;

    get isDirty() {
        return this._isDirty;
    }
    set isDirty(value: boolean) {
        this._isDirty = value;
        this.onDirtyStateChange.next(value);
    }

    constructor(
        private translateService: TranslateService,
        private smService: SmService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private quickEditService: QuickEditService,
        private cookieService: CookieService) {
        this.initialize();
    }

    ngOnDestroy(): void {
        if (this.uploadWorkJsonSubscription) { this.uploadWorkJsonSubscription.unsubscribe(); }
        if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
    }

    public initialize() {
        this.guid = Guid.create().toString();
        this.isShared = false;
        this.copyType = this.COPY_TYPE.ALL;
        this.mapOperations = new MapOperations(this.authenticationService.getUserEmail());
        this.setLoaded(false);
        this.canvasOrigin = new Point(0, 0);
        this.editOnText = false;
        this.lastSaveIndex = -1;
        this.index = -1;
        this.isDirty = false;
        this.isNew = false;
        this.error = '';
        this.mapLanguage = '';
        this.selectionData = '';
        this.forceExit = false;
        this.dropping = false;
        this.isCopyingMapImage = false;
        this.setCanEnableQuickEdit(true);
        this.setFocusOnMap(true);
        const storedLpss = localStorage.getItem('limitPrintSize');
        const lpss: string = (storedLpss ? storedLpss : '');
        const lps: number = parseInt(lpss, 10);
        if (lps >= 1000) { // PRINTSIZE (at least 1000px!!!)
            this._limitPrintSize = lps;
        }
    }

    async loadFonts(): Promise<void> {
        const fontFamilies = [
            'Architects Daughter',
            'IBM Plex Mono',
            'Montserrat',
            'Roboto',
            'Nunito',
            'Special Elite'
        ];
        const fontPromises = fontFamilies.map(font =>
            document.fonts.load(`1rem ${font}`)
        );
        await Promise.all(fontPromises);
        console.log('All fonts loaded!');
    }

    public resetPasteState(value: boolean) {
        this.onChangePasteState.emit(value);
    }

    public clear() {
        this.initialize();
    }

    public EditImage() {
        this.onStartEditImage.emit();
    }

    public lockUI(locked: boolean, busy?: boolean) {
        this.isLocked = locked;
        this.setEditingState(locked);
        this.setCanEnableQuickEdit(!locked);
        this.smService.setEnableKeyPresses(!locked);
        this.setBusyState((busy ? busy : false));
    }

    public setFocusOnMap(mapHasFocus: boolean) {

        if (this.isFocusOnMap !== mapHasFocus) {
            this.isFocusOnMap = mapHasFocus;


            this.smService.setEnableKeyPresses(mapHasFocus);
            if (!mapHasFocus) {
                this.quickEditService.disableAll();
            }
            this.onMapGetFocus.emit(mapHasFocus);
        }
        // if (mapHasFocus) {
        //     this.smService.setEnableKeyPresses(true);
        // }


    }

    public getFocusOnMap(): boolean {
        return this.isFocusOnMap;
    }

    public forceAutoSave() {
        this.onForceAutosave.next('');
    }

    public setLoaded(value: boolean) {
        this.isLoaded = value;
        this.onLoaded.next(value);
    }

    public setBusyState(value: boolean) {
        this.isBusy = value;
        this.onBusyStateChange.next(value);
    }

    public getBusyState(): boolean {
        return this.isBusy;
    }

    public setAutosaving(value?: boolean) {
        this.autoSaving = (value ? value : false);
        this.onAutoSavingChange.next(value);
    }

    public getAutosaving(): boolean {
        return this.autoSaving;
    }

    public setEditingState(value?: boolean) {
        this.isEditing = (value ? value : false);
        this.onEditingChange.next(value);
    }

    public getEditingState() {
        return this.isEditing;
    }

    public setStateError(err: string) {
        this.error = err;
        this.onError.next(err);
    }

    public setCanEnableQuickEdit(value: boolean) {
        this.canEnableQuickEdit = value;
        this.onCanEnableQuickEditChange.emit(value);
    }

    public setBackgroundColor(value: string) {
        if (value) {
            const oldColor = this.smService.getBackgroundColor();
            if (oldColor !== value) {
                this.smService.setBackgroundColor(value);
                this.onChangedBackColor.emit(value);
            }
        }
    }

    public autoRenameNewMap(mapName: string) {
        if (this.isNew && this.name === this.translateService.instant('NEW_MAP') && mapName.trim() !== '') {
            // Avoid rename on a link
            if (!mapName.startsWith('http://') && !mapName.startsWith('https://')) {
                this.name = mapName;
                if (mapName.length > 25) {
                    this.name = mapName.substr(0, 25);
                }
                this.workJson = this.smService.setMapName(this.name);
                this.isDirty = true;
                this.smeService.findOwnerIdByMapId(this.id).subscribe((result: any) => {
                    const ownerId = result.ownerId;
                    if (this.uploadWorkJsonSubscription) { this.uploadWorkJsonSubscription.unsubscribe(); }
                    this.uploadWorkJsonSubscription = this.smeService.uploadWorkJson(ownerId, this.id, this.workJson).subscribe(() => {
                        // this.isDirty = false;
                        console.log(`Auto renamed map to ${this.name}`);
                    });
                });

                if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
                this.renameMapSubscription = this.smeService.renameMap(this.id, this.name + '.sme').subscribe(() => {
                });

                this.onChangeMapName.next(this.name);
            }
        }
    }

    public emitUserEvent(event: any) {
        this.onEventUser.emit(event);
    }

    private getClearText(strSrc: string) {
        let s = strSrc;
        s = s.replace(/\<br \/>/g, '\n');
        s = s.replace(/\<br\/>/g, '\n');
        s = s.replace(/&nbsp;/g, '');
        s = s.replace(/<[^<|>]+?>/gi, '');
        const txt = document.createElement("textarea");
        txt.innerHTML = s;
        s = txt.value;
        return s;
    }

    public getPlainText(HtmlText: string): string {
        return this.getClearText(HtmlText); //.trim();
    }

    public setPrintQuality(level: number) {
        if (level >= 0 && level < this.PRINT_QUALITY_SIZES.length) {
            this.limitPrintSize = this.PRINT_QUALITY_SIZES[level];
        }
    }

    public getPrintQuality() {
        let level = 5;
        let found = false;
        let i = 0;
        while (!found && i < this.PRINT_QUALITY_SIZES.length) {
            if (this.PRINT_QUALITY_SIZES[i] >= this.limitPrintSize) {
                level = i;
                found = true;
            } else {
                i++;
            }
        }
        return level;
    }

    public set isFormatCopying(value: boolean) {
        this._isFormatCopying = value;
        this.onCopyFormatStateChange.emit(value);
    }

    public get isFormatCopying() {
        return this._isFormatCopying;
    }

    public setCopyType(type: string) {
        this.copyType = type;
        // Fallback to ALL (Ctrl-C does not set type)
        setTimeout(() => {
            this.copyType = this.COPY_TYPE.ALL;
        }, 200);
    }

    public goToWebsiteClick(from: string, type: string) {
        if (type === 'private') {
            if (from === 'order-list') {
                // order-list
                // console.log('+++++https://www.anastasis.it/supermappex-privati');
                window.open('https://www.anastasis.it/supermappex-privati', '_blank');
            } else if (from === 'invalid-credentials') {
                // invalid-credentials
                // console.log('+++++https://www.anastasis.it/supermappex-privati-info');
                window.open('https://www.anastasis.it/supermappex-privati-info', '_blank');
            }
        } else if (type === 'school') {
            // console.log('+++++https://www.anastasis.it/supermappex-scuole');
            window.open('https://www.anastasis.it/supermappex-scuole', '_blank');
        }
    }

    public setSpellCheckerLanguages(lang: string, n: number = 0) {
        // GUI language
        const cookies = this.cookieService.getAll();
        const guiLangCookie = this.cookieService.get('GUILanguage');
        if (guiLangCookie) {
            (window as any).WEBSPELLCHECKER_CONFIG.localization = guiLangCookie;
        }
        // Spell checker language
        const MAX_RETRIES = 20;
        setTimeout(() => {
            const instances = (window as any).WEBSPELLCHECKER.getInstances();
            if (instances.length > 0) {
                console.log(`+++++WPR INSTANCE FOUND!!! (${n}) Lang=${lang}`);
                const inst = instances[0];
                const l = lang.replace('-', '_');
                inst.setLang(l);
            } else {
                if (n <= MAX_RETRIES) {
                    console.log(`-----NO WProofReader INSTANCES found (try #${n}) Lang=${lang}`);
                    this.setSpellCheckerLanguages(lang, n + 1);
                } else {
                    console.log(`-----MAX RETRIES EXCEEDED: NO WProofReader INSTANCES found`);
                }
            }
        }, 250);
    }

    public getInitials(fullname?: string) {
        let result = '';
        const userfullname = fullname || this.authenticationService.getUserFullname();
        if (userfullname && userfullname.trim() !== '') {
            userfullname.split(' ').forEach((name) => {
                result += (name && name.length > 0 ? name[0] : '');
            });
        }
        if (result.length === 0) {
            result = this.translateService.instant('SHARE_NONAME');
        } else if (result.length > 2) {
            result = result.substring(0, 2);
        }
        return result.toUpperCase();
    }

}
