<!-- BUTTON USED TO MOVE AND PLACE THE MENU ON THE SCREEN -->
<button #menuButton mat-button [matMenuTriggerFor]="menu" class="menu-button"></button>

<mat-menu #menu="matMenu" (close)="onMenuClosed()" [overlapTrigger]="false">
    <!-- Add new node -->
    <!-- <div [hidden]="isSelectionPresent"> -->
    <div mat-menu-item (click)="addNewNode()" *ngIf="!isSelectionPresent">
        <mat-icon color="primary" svgIcon="add-node"></mat-icon>
        <span translate>GENERIC_TOOLBAR_INSERT_NEW_NODE</span>
    </div>
    <!-- </div> -->
    <!-- Add new table -->
    <!-- <div [hidden]="isSelectionPresent"> -->
    <div mat-menu-item (click)="addNewTable()" *ngIf="!isSelectionPresent">
        <mat-icon color="primary" svgIcon="table-plus"></mat-icon>
        <span translate>GENERIC_TOOLBAR_INSERT_NEW_TABLE</span>
    </div>
    <!-- </div> -->

    <!-- Edit text -->
    <!-- <div> -->
    <div *ngIf="isSingleSelectionPresent" mat-menu-item (click)="editText()">
        <mat-icon color="primary" svgIcon="square-edit-outline"></mat-icon>
        <span>{{editTitle}}</span>
    </div>

    <!-- </div> -->
    <!-- Open Math editor -->
    <div *ngIf="isMathEnabled && !isSelectionPresent" mat-menu-item (click)="openMath()">
        <mat-icon color="primary" fontSet="material-icons-outlined">functions</mat-icon>
        <span translate>GENERIC_TOOLBAR_MATH_EDITOR</span>
    </div>

    <!-- </div> -->
    <div *ngIf="isSingleNodeSelected && isImagesPresent" mat-menu-item (click)="cropImage()">
        <mat-icon color="primary" svgIcon="image-edit-outline"></mat-icon>
        <span translate>EDIT_IMAGE</span>
    </div>

    <!-- Insert image -->
    <div *ngIf="isSingleNodeSelected" mat-menu-item [matMenuTriggerFor]="insertImage">
        <mat-icon color="primary" svgIcon="image-plus"></mat-icon>
        <span translate>IMAGE</span>
        <mat-menu #insertImage="matMenu" [overlapTrigger]="false">
            <app-image-menu></app-image-menu>
        </mat-menu>
    </div>


    <!-- Edit deep text -->
    <div *ngIf="isSingleNodeSelected" mat-menu-item (click)="editDeep()">
        <mat-icon color="primary" svgIcon="add-deep"></mat-icon>
        <span translate>DEEPTEXT</span>
    </div>

    <!-- Edit math -->
    <div *ngIf="isMathEnabled && isSingleNodeSelected" mat-menu-item (click)="editMath()">
        <mat-icon color="primary" fontSet="material-icons-outlined">functions</mat-icon>
        <span translate>INSERT_MATH</span>
    </div>

    <!-- Insert menu -->
    <div *ngIf="isSingleNodeSelected" mat-menu-item [matMenuTriggerFor]="linksMenu">
        <mat-icon color="primary" fontSet="material-icons-outlined">attach_file</mat-icon>
        <span translate>INSERT-ATTACHMENT</span>
    </div>
    <mat-menu #linksMenu="matMenu" [overlapTrigger]="false">
        <!-- Insert (or edit) link -->
        <div mat-menu-item class="custom-item" (click)="editLink()">
            <mat-icon color="primary" fontSet="material-icons-outlined">insert_link</mat-icon>
            <span translate>LINKS</span>
        </div>
        <!-- Insert (or edit) link from Map (Google Drive) -->
        <!-- <div *ngIf="isGoogleUser()" mat-menu-item class="custom-item" (click)="editLinkGoogleDrive('map')">
            <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
            <span translate>LINKS-MAP</span>
        </div> -->
        <!-- Insert (or edit) link from Google Drive -->
        <div *ngIf="isGoogleUser()" mat-menu-item (click)="editLinkGoogleDrive('drive')">
            <mat-icon color="primary" svgIcon="google-drive"></mat-icon>
            <span translate>LINKS-GOOGLE-DRIVE</span>
        </div>
    </mat-menu>
    <!-- Search on web -->
    <div *ngIf="isSingleNodeSelected || !isSelectionPresent" mat-menu-item [matMenuTriggerFor]="searchMenuNode">
        <mat-icon color="primary" fontSet="material-icons-outlined">travel_explore</mat-icon>
        <span translate>SEARCH_ON_WEB</span>
        <mat-menu #searchMenuNode="matMenu" [overlapTrigger]="false">
            <!-- Search on google images -->
            <div mat-menu-item (click)="searchOnWeb('googleimages')">
                <mat-icon color="primary" fontSet="material-icons-outlined">image_search</mat-icon>
                <span translate>GOOGLE_IMAGES</span>
            </div>
            <!-- Search on wikipedia -->
            <div mat-menu-item (click)="searchOnWeb('wikipedia')">
                <mat-icon color="primary" svgIcon="wikipedia"></mat-icon>
                <span translate>WIKIPEDIA</span>
            </div>
            <!-- Search on youtube -->
            <div mat-menu-item (click)="searchOnWeb('youtube')">
                <mat-icon color="primary" svgIcon="youtube"></mat-icon>
                <span translate>YOUTUBE</span>
            </div>
            <!-- Search on google -->
            <div mat-menu-item (click)="searchOnWeb('google')">
                <mat-icon color="primary" svgIcon="google"></mat-icon>
                <span translate>GOOGLE</span>
            </div>
        </mat-menu>

    </div>
    <mat-divider *ngIf="isEditMenuEnabled || isSingleNodeSelected || !isSelectionPresent"></mat-divider>

    <!-- Read -->
    <div *ngIf="isTitleTextPresent" mat-menu-item (click)="readTitleText()">
        <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">sms</mat-icon>
        <span translate>READ_TITLE</span>

    </div>
    <mat-divider *ngIf="isTitleTextPresent"></mat-divider>

    <!-- Break arrow -->
    <div *ngIf="isOnlyEdgeSelected" mat-menu-item (click)="breakEdge()">
        <mat-icon color="primary" svgIcon="ray-vertex"></mat-icon>
        <span translate>MAIN_MENU_FORMAT-ARROW-BREAKARROW</span>
    </div>

    <!--Archi curvi/dritti-->
    <!-- <div *ngIf="isOnlyEdgeSelected" mat-menu-item [matMenuTriggerFor]="edgeType">
        <mat-icon color="primary" fontSet="material-icons-outlined">north_east</mat-icon>
        <span color="primary" translate>FORMAT_NODE_TOOLBAR-CURVE-EDGE</span>
    </div>
    <mat-menu #edgeType="matMenu" color="primary" [overlapTrigger]="false">
        <div mat-menu-item (click)="curvedEdge(false)">
            <mat-icon fontSet="material-icons-outlined" color="primary">east</mat-icon>
            <span color="primary" translate>EDGE_STRAIGHT</span>
        </div>
        <div mat-menu-item (click)="curvedEdge(true)">
            <mat-icon fontSet="material-icons-outlined" color="primary">redo</mat-icon>
            <span color="primary" translate>EDGE_CURVE</span>
        </div>
    </mat-menu> -->

    <!-- Arrange -->
    <div *ngIf="isSingleNodeSelected || isMultiSelectionPresent || isOnlyEdgeSelected" mat-menu-item [matMenuTriggerFor]="arrangeMenu">
        <mat-icon color="primary">swap_vert</mat-icon>
        <span translate>MAIN_MENU_ARRANGE-ORDER</span>
    </div>
    <mat-menu #arrangeMenu="matMenu" [overlapTrigger]="false">
        <!-- Bring to front -->
        <div mat-menu-item (click)="bringToFront()">
            <mat-icon color="primary" svgIcon="arrange-bring-forward"></mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ORDER-BRINGTOFRONT</span>
        </div>
        <!-- Send to back -->
        <div mat-menu-item (click)="sendToBack()">
            <mat-icon color="primary" svgIcon="arrange-send-backward"></mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ORDER-SENDTOBACK</span>
        </div>
    </mat-menu>

    <!-- Align -->
    <div *ngIf="canAlign" mat-menu-item color="primary" [matMenuTriggerFor]="arrangeAlignMenu">
        <mat-icon color="primary" fontSet="material-icons-outlined">align_horizontal_left</mat-icon>
        <span translate>MAIN_MENU_ARRANGE-ALIGN</span>
    </div>
    <mat-menu #arrangeAlignMenu="matMenu" [overlapTrigger]="false">
        <div *ngFor="let al of alignments" mat-menu-item (click)="changeAlign(al.value)">
            <mat-icon color="primary" [svgIcon]="al.icon"></mat-icon>
            <span translate>{{al.viewValue}}</span>
        </div>
    </mat-menu>

    <!-- Size -->
    <div *ngIf="canResize" mat-menu-item color="primary" [matMenuTriggerFor]="arrangeSizeMenu">
        <mat-icon color="primary">tab_unselected</mat-icon>
        <span translate>MAIN_MENU_ARRANGE-SIZE</span>
    </div>
    <mat-menu #arrangeSizeMenu="matMenu" [overlapTrigger]="false">
        <div *ngFor="let s of sizes" mat-menu-item (click)="changeSize(s.value)">
            <mat-icon color="primary" [svgIcon]="s.icon"></mat-icon>
            <span translate>{{s.viewValue}}</span>
        </div>
    </mat-menu>

    <mat-divider *ngIf="isOnlyEdgeSelected || isEdgeCurveSelected || isSingleNodeSelected || isMultiSelectionPresent || canAlign || canResize"></mat-divider>

    <!-- Copy format -->
    <div *ngIf="isSingleSelectionPresent" mat-menu-item color="primary" (click)="copyFormat()">
        <mat-icon color="primary" [ngClass]="{'button-down': isFormatCopying}">format_paint</mat-icon>
        <span translate>FORMAT_COPY_FORMAT</span>

    </div>

    <!-- Cut -->
    <div *ngIf="isSelectionPresent" mat-menu-item class="custom-item" color="primary" (click)="cutSelection()">
        <mat-icon color="primary">content_cut</mat-icon>
        <span translate>MAIN_MENU_EDIT-CUT</span>

    </div>
    <!-- Copy -->
    <div *ngIf="isSelectionPresent && !isCopyExtended" mat-menu-item color="primary" (click)="copySelection('all')">
        <mat-icon color="primary" fontSet="material-icons-outlined">content_copy</mat-icon>
        <span translate>MAIN_MENU_EDIT-COPY</span>

    </div>
    <div *ngIf="!isMultiSelectionPresent && isCopyExtended" mat-menu-item color="primary" [matMenuTriggerFor]="copyMenu">
        <mat-icon color="primary">content_copy</mat-icon>
        <span translate>MAIN_MENU_EDIT-COPY</span>
    </div>
    <mat-menu #copyMenu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item (click)="copySelection('all')" color="primary">
            <mat-icon color="primary" fontSet="material-icons-outlined">copy_all</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-ALL</span>
        </div>
        <div *ngIf="isTitleTextPresent" mat-menu-item color="primary" (click)="copySelection('text')">
            <mat-icon color="primary" svgIcon="alphabetical"></mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-TEXT</span>
        </div>
        <div *ngIf="isDeepTextPresent" mat-menu-item color="primary" (click)="copySelection('deep')">
            <mat-icon color="primary" svgIcon="tooltip-text"></mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-DEEP</span>
        </div>
        <div *ngIf="isImagePresent" mat-menu-item class="custom-item" color="primary" (click)="copySelection('image')">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">image</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-IMAGE</span>
        </div>
        <div *ngIf="isLinkNoMathPresent" mat-menu-item color="primary" (click)="copySelection('link')">
            <mat-icon color="primary" fontSet="material-icons-outlined">attach_file</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-LINK</span>
        </div>
    </mat-menu>
    <!-- Delete table -->
    <!-- <mat-menu #deleteMenu="matMenu" [overlapTrigger]="false">
        <div *ngIf="isTableSelected" mat-menu-item class="custom-item" (click)="deleteTable()">
            <mat-icon class="custom-icon" svgIcon="table-plus"></mat-icon>
            <span class="custom-span" translate>MAIN_MENU_FORMAT-TABLE</span>
        </div>
    </mat-menu> -->
    <!-- Paste -->
    <div mat-menu-item class="custom-item" (click)="pasteSelection()" color="primary">
        <mat-icon color="primary">content_paste</mat-icon>
        <span translate>MAIN_MENU_EDIT-PASTE</span>
    </div>
    <!-- Delete -->
    <div *ngIf="canDelete" mat-menu-item class="custom-item" color="primary" [matMenuTriggerFor]="deleteMenu">
        <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">delete</mat-icon>
        <span translate>DELETE</span>
    </div>
    <!-- Delete table -->
    <mat-menu #deleteMenu="matMenu" [overlapTrigger]="false">
        <div *ngIf="isTableSelected" mat-menu-item color="primary" (click)="deleteTable()">
            <mat-icon color="primary" svgIcon="table-plus"></mat-icon>
            <span translate>MAIN_MENU_FORMAT-TABLE</span>
        </div>
        <!-- Delete node / edge -->
        <div *ngIf="!isTableSelected && isOnlyEdgeSelected" (click)="deleteSelection()" mat-menu-item color="primary">
            <mat-icon>call_made</mat-icon>
            <span translate>DELETE_EDGE</span>
        </div>
        <div *ngIf="!isTableSelected && !isOnlyEdgeSelected" mat-menu-item color="primary" (click)="deleteSelection()">
            <mat-icon color="primary" fontSet="material-icons-outlined">rectangle</mat-icon>
            <span translate>DELETE_NODE</span>
        </div>
        <!-- Delete title text -->
        <div *ngIf="isTitleTextPresent" mat-menu-item (click)="deleteText()" color="primary">
            <mat-icon color="primary" svgIcon="alphabetical"></mat-icon>
            <span translate>DELETE_TEXT</span>

        </div>
        <!-- Delete deep text -->
        <div *ngIf="isDeepTextPresent" mat-menu-item (click)="deleteDeepText()" color="primary">
            <mat-icon color="primary" svgIcon="tooltip-text"></mat-icon>
            <span translate>DELETE_DEEPTEXT</span>

        </div>
        <!-- Delete image -->
        <div *ngIf="isImagesPresent" mat-menu-item (click)="deleteImage()" class="custom-item" color="primary">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">image</mat-icon>
            <span translate>DELETE_IMAGE</span>

        </div>
        <!-- Delete math -->
        <div *ngIf="isMathLinkPresent" mat-menu-item (click)="deleteMathLink()" color="primary">
            <mat-icon color="primary" fontSet="material-icons-outlined">functions</mat-icon>
            <span translate>INSERT_MATH</span>
        </div>
        <!-- Delete link -->
        <div *ngIf="isLinkNoMathPresent" mat-menu-item (click)="deleteLink()" color="primary">
            <mat-icon color="primary" fontSet="material-icons-outlined">attach_file</mat-icon>
            <span translate>ATTACHMENT</span>
        </div>

        <!-- <div *ngIf="!mainLinkIsVideo">
      <mat-icon class="custom-icon" svgIcon="link"></mat-icon>
      <span class="custom-span" translate>DELETE_LINK</span>
    </div>
    <div *ngIf="mainLinkIsVideo">
      <mat-icon class="custom-icon" svgIcon="youtube"></mat-icon>
      <span class="custom-span" translate>DELETE_VIDEO</span>
    </div> -->
        <!-- </div> -->
    </mat-menu>

    <div [hidden]="(!isSelectionPresent || canDelete)">
        <div *ngIf="isTableSelected && !isMultiSelectionPresent" mat-menu-item color="primary" (click)="deleteTable()">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">delete</mat-icon>
            <span translate>TABLE_TOOLBAR-REMOVE-TABLE</span>
        </div>
        <div *ngIf="!isTableSelected || isMultiSelectionPresent" mat-menu-item (click)="deleteSelection()">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">delete</mat-icon>
            <span translate>MAIN_MENU_EDIT-DELETE</span>
        </div>
    </div>
    <!-- Quick edit -->
    <div *ngIf="!isSelectionPresent" mat-menu-item color="primary" (click)="quickEdit()">
        <mat-icon color="primary" fontSet="material-icons-outlined">rtt</mat-icon>
        <span translate>MAIN_MENU_INSERT-QUICKEDIT</span>

    </div>
    <!-- Quick speech -->
    <div *ngIf="!isSelectionPresent && !platformService.isMobileOrTabletDevice()" mat-menu-item color="primary" (click)="quickSpeech()">
        <mat-icon color="primary">settings_voice</mat-icon>
        <span translate>MAIN_MENU_INSERT-QUICKSPEECH</span>

    </div>
</mat-menu>
