import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { AdminEditNotificationComponent } from '../admin-edit-notification/admin-edit-notification.component';


const logger: Logger = new Logger('admin-move-maps');

@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-move-maps.component.html',
    styleUrls: ['./admin-move-maps.component.scss']
})
export class AdminMoveMapsComponent implements OnInit {

    public isLoading = false;
    public user = '';
    public role = '';

    public oldUserID: string = '';
    public newUserID: string = '';

    // @ViewChild('') notificationForm: AdminEditNotificationComponent | undefined;

    constructor(private authenticationService: AuthenticationService, private smeService: SmeService, private translateService: TranslateService) {
        this.user = this.authenticationService.getUserName();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : '';
    }

    ngOnInit() {
        console.log('init');
    }

    changeOldUserEmail($event: any) {
        this.oldUserID = $event.target.value;
    }

    changeNewUserEmail($event: any) {
        this.newUserID = $event.target.value;
    }

    moveMaps() {
       
        if (this.newUserID && this.oldUserID) {
             this.isLoading = true;
        
            this.smeService.moveMapsinEmail(this.oldUserID, this.newUserID).subscribe({
                next: (res) => {
                    this.isLoading = false;
                    if (res && res.result && res.result.resMaps === true && res.result.resAllMaps === true) {
                        alert('OK!');
                    } else {
                        alert(JSON.stringify(res));
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                    console.error(error);
                    alert('ERROR: ' + JSON.stringify(error));
                }
            });
        } else {
            alert("Dato obbligatorio mancante");
        }
    }

}
