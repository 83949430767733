<h2 mat-dialog-title translate class="deep-title">DEEPTEXT</h2>
<div class="deep-container">
    <div class="toolbar-container">
        <div class="deep-toolbar">
            <div class="deep-toolbar-top">
                <button class="green-color" *ngIf="!isTalking" mat-icon-button matTooltip="{{'PLAY' | translate}}" [attr.aria-label]="'PLAY' | translate" (click)="TTSRead()" matTooltipPosition="above">
                    <mat-icon>play_arrow</mat-icon>
                </button>
                <button class="red-color" *ngIf="isTalking" mat-icon-button matTooltip="{{'STOP' | translate}}" [attr.aria-label]="'STOP' | translate" (click)="TTSStop()" matTooltipPosition="above">
                    <mat-icon>stop</mat-icon>
                </button>
                <div class="deep-toolbar-top-input-container">
                    <input class="deep-toolbar-top-input" #speedInput type="number" min="0.5" max="2" step="0.1" (focus)="speedGotFocus()" (blur)="speedLostFocus()" value="{{rateValue}}" (change)="changeSpeechRate($event)" />
                </div>
                <button class="lang-button" [matMenuTriggerFor]="languageMenu" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" matTooltipPosition="above">
                    <img [src]="selectedLangIcon" />
                </button>
                <mat-menu class="language-menu-container" #languageMenu="matMenu" color="primary" [overlapTrigger]="false">
                    <div *ngFor="let l of languagesWithFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
                        <img [src]="l.icon" class="language-icon" />
                        <span translate>{{l.viewValue}}</span>
                    </div>
                    <ng-container *ngIf="languagesNoFlag.length > 0">
                        <mat-divider></mat-divider>
                        <button class="language-menu-item-container" mat-menu-item [matMenuTriggerFor]="languageNoFlagMenu">{{'OTHER_VOICES' | translate}}</button>
                        <mat-menu class="language-menu-container" #languageNoFlagMenu="matMenu" color="primary">
                            <div *ngFor="let l of languagesNoFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
                                <img [src]="l.icon" mat-icon class="language-icon" />
                                <span translate>{{l.viewValue}}</span>
                            </div>
                        </mat-menu>
                    </ng-container>
                </mat-menu>
                <button *ngIf="editVisible && !isMobileDevice" mat-icon-button color="primary" (click)="speechEdit()" [ngClass]="{'speechedit-mode': speechEditEnabled}" matTooltip="{{'MAIN_TOOLBAR_SPEECHEDIT' | translate}}" matTooltipPosition="above">
                    <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
                </button>
                <div class="spacer"></div>
                <button *ngIf="editVisible" mat-icon-button [matMenuTriggerFor]="searchMenu">
                    <mat-icon color="primary" fontSet="material-icons-outlined">travel_explore</mat-icon>
                </button>
                <mat-menu #searchMenu="matMenu" color="primary" [overlapTrigger]="false">
                    <button mat-menu-item color="primary" (click)="searchWeb('google-img')">
                        <mat-icon color="primary" [attr.aria-label]="'SEARCH_GOOGLEIMG' | translate" svgIcon="image-search"></mat-icon>
                        <span>{{'SEARCH_GOOGLEIMG' | translate}}</span>
                    </button>
                    <button mat-menu-item color="primary" (click)="searchWeb('google')">
                        <mat-icon color="primary" [attr.aria-label]="'SEARCH_GOOGLE' | translate" svgIcon="google"></mat-icon>
                        <span>{{'SEARCH_GOOGLE' | translate}}</span>
                    </button>
                    <button mat-menu-item color="primary" (click)="searchWeb('youtube')">
                        <mat-icon color="primary" [attr.aria-label]="'SEARCH_YOUTUBE' | translate" svgIcon="youtube"></mat-icon>
                        <span>{{'SEARCH_YOUTUBE' | translate}}</span>
                    </button>
                    <button mat-menu-item color="primary" (click)="searchWeb('wikipedia')">
                        <mat-icon color="primary" [attr.aria-label]="'SEARCH_WIKIPEDIA' | translate" svgIcon="wikipedia"></mat-icon>
                        <span>{{'SEARCH_WIKIPEDIA' | translate}}</span>
                    </button>
                </mat-menu>
            </div>
            <div id="deep-toolbar-ckeditor" *ngIf="editVisible" class="deep-toolbar-ckeditor">
            </div>
        </div>
    </div>
    <div class="bottom-container">
        <div class="deepeditor" #htmlView>
            <div class="deep-content" id="deep-content">
                <div id="deepeditor-container" class="deepeditor-container" contenteditable="true" [innerHTML]="htmlSafe" [hidden]="!editVisible"> </div>
                <div class="deep-html" [innerHTML]="htmlSafe" [hidden]="editVisible" (click)="clickLink($event)"></div>
            </div>
        </div>
        <div class="show-node-image-button-container">
            <button mat-stroked-button color="primary" (click)="toggleNodeImage()">
                <span *ngIf="!showNodeImage" translate>DEEP_SHOW_IMAGE</span>
                <span *ngIf="showNodeImage" translate>DEEP_HIDE_IMAGE</span>
            </button>
        </div>
        <div *ngIf="imagePresent && showNodeImage" class="node-image-container">
            <img [src]="imageNodeUrl" class="image-container" />
        </div>
    </div>
    <button cdkFocusInitial *ngIf="canEdit && !editVisible" mat-fab class="editbtn" color="accent" matTooltip="{{'DEEP_OPEN_MODIFY' | translate}}" (click)="editDeep()">
        <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">edit</mat-icon>
    </button>
    <div class="speechedit-container" [hidden]="!speechEditEnabled">
        <mat-icon color="primary">mic</mat-icon>
        <span #speechInput> </span>
    </div>
</div>
<button class="close-button" mat-icon-button color="primary" [mat-dialog-close]="true">
    <mat-icon fontSet="material-icons-outlined">close</mat-icon>
</button>
