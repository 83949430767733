<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
    </div>

    <div class="user">
        <span class="label" translate>MOVE_MAPS_OLDUSER</span>
        <input class="input" type="text" (change)="changeOldUserEmail($event)" spellcheck="false" />
    </div>
    <div class="user">
        <span class="label" translate>MOVE_MAPS_NEWUSER</span>
        <input class="input" type="text" (change)="changeNewUserEmail($event)" spellcheck="false" />
    </div>
    <div class="user">
        <button mat-flat-button (click)="moveMaps()">{{'MOVE_MAPS' | translate}}</button>
    </div>
</div>
